body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: max(7vw, 30px);
  margin: auto;
  text-align: center;
}

h2 {
  font-size: max(2vw, 18px);
  margin-top: 50px;
  font-weight: bold;
}

h3 {
  font-size: max(1.5vw, 15px);
  font-weight: bold;
}

.mainDiv{
  padding-bottom:60px;
  overflow-x: hidden;
  overflow-y: auto;
  font-family:Verdana, Geneva, Tahoma, sans-serif
}

.headerPic {
  max-width: max(25%, 150px);
  height: auto;
  padding-left: 10;
}

.footer {
  background-color:lightsteelblue;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footerText{
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
  bottom: 0;
  left: 0;
  width: 100%;
}

.socialsPic {
  width: min(25%, 40px);
}

.infoText {
  font-size: 14px;
  margin-left: 40px;
  margin-right: 40px;
  padding-bottom: 300px;
}

.foot {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: lightsteelblue;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: min(3vw, 16px);
}

.left {
  margin-top: 15px;
  flex: 25%;
}
.middle{
  flex: 50%;
}
.right {
  margin-top: 20px;
  flex: 25%;
}

.notification {
  display: inline-block;
  width: min(60vw, 20000px);
  height: max(calc(100% - 25vw), 60px);
  border: 3px solid rgb(234, 155, 93);
  align-self: center;
  margin-left: 7%;
  margin-right: 10%;
}

.notificationText {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-size: min(2.5vw, 20px);
}